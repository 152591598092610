import { FC } from 'react';
import { useGallery } from '../../state/hooks';
import { Image } from '../../components';

const Works: FC = () => {
  const { data = [], isLoading } = useGallery();
  return (
    <div>
      {isLoading && (
        <div className="w-full flex items-center justify-center h-screen absolute top-0">
          <span className="w-full text-center text-lg">Loading...</span>
        </div>
      )}
      <div className="relative container columns-1 sm:columns-3 gap-8 space-y-16 md:space-y-8 mx-auto mt-32">
        {!isLoading &&
          !!data.length &&
          data.map((img: any, i: number) => (
            <div className={`w-full object-fit overflow-hidden group relative ${i % 2 ? 'aspect-video' : 'aspect-square'}`}>
              <Image animate className="w-full h-full" lazy key={img?.title} alt={img?.title} src={img?.media?.url} />
              <div className="flex mt-4 md:mt-2 justify-between">
                <span className="italic">{img.title}</span>
                <div className="flex flex-col items-end  hover:opacity-100">
                  <span className="truncate">{img.material}</span>
                  <span className="">{img.dimensions}</span>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Works;
