import Helmet from 'react-helmet';
import { Image } from '../../components';

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Kristen Djin</title>
      </Helmet>
      <div className="contianer mt-32 flex items-center justify-center">
        <div className="flex w-[700px] h-[500px] items-center justify-center z-10">
          <Image
            className="w-[600px] absolute -mt-10 -ml-10"
            src="https://images.ctfassets.net/463cmhf5bi9o/5ssmhPA7pIXtwcjSz8oTqE/1e15a54cfca2e4201637bdb127032fdf/joined_video_4feb82d757a64393ba6e8b35af763424_AdobeExpress__3_.gif"
          />

          <Image
            className="w-[600px] absolute"
            src="https://images.ctfassets.net/463cmhf5bi9o/5ssmhPA7pIXtwcjSz8oTqE/1e15a54cfca2e4201637bdb127032fdf/joined_video_4feb82d757a64393ba6e8b35af763424_AdobeExpress__3_.gif"
          />
          <Image
            className="w-[600px] absolute mt-10 ml-10"
            src="https://images.ctfassets.net/463cmhf5bi9o/5ssmhPA7pIXtwcjSz8oTqE/1e15a54cfca2e4201637bdb127032fdf/joined_video_4feb82d757a64393ba6e8b35af763424_AdobeExpress__3_.gif"
          />
        </div>
        <div className="flex w-[500px] md:w-[700px] h-[500px] items-center justify-center absolute z-0 px-4 md:px-0 opacity-0">
          <Image
            className="w-[400px] absolute -mt-10 -ml-10"
            src="https://images.ctfassets.net/463cmhf5bi9o/6lPXUrqlJNOYADh1hQiZl7/bc757a4ae6757d3ff8320076abe24083/Snaptik_app_7223622864547728682_AdobeExpress__3_.gif"
          />

          <Image
            className="w-[400px] absolute"
            src="https://images.ctfassets.net/463cmhf5bi9o/6lPXUrqlJNOYADh1hQiZl7/bc757a4ae6757d3ff8320076abe24083/Snaptik_app_7223622864547728682_AdobeExpress__3_.gif"
          />
          <Image
            className="w-[400px] absolute mt-10 ml-10"
            src="https://images.ctfassets.net/463cmhf5bi9o/6lPXUrqlJNOYADh1hQiZl7/bc757a4ae6757d3ff8320076abe24083/Snaptik_app_7223622864547728682_AdobeExpress__3_.gif"
          />
        </div>
      </div>
      <div className="w-screeb flex items-center justify-center mt-10 text-center">
        <span>Kristen Djin is a multidisciplinary artist and designer based in Los Angeles, CA</span>
      </div>
    </>
  );
};

export default Home;
