import { gql } from 'graphql-request';

const galleryQuery = () => gql`
  {
    homeShowcaseCollection {
      items {
        title
        material
        dimensions
        media {
          url
          width
          height
        }
      }
    }
  }
`;

export default galleryQuery;
