import React from 'react';
import ReactDOM from 'react-dom/client';
import { createGlobalStyle } from 'styled-components';
import './index.css';
import App from './App';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient();
const GlobalStyle = createGlobalStyle`
  html, body {
   font-size: 14px;
   backface-visibility: hidden;
   overflow-x: hidden;
   background: #F7F7F5;
   font-family: 'kd';
  //  cursor: none;
  
  }

  * {  -webkit-backface-visibility:  hidden;
    -webkit-tap-highlight-color:  transparent;
    box-sizing: border box
  }

  *:focus {
    outline: 0 !important;
  } 
`;

root.render(
  <QueryClientProvider client={queryClient} contextSharing>
    <GlobalStyle />
    <App />
  </QueryClientProvider>,
);
