/* eslint-disable radix */
/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import { useQuery } from '@tanstack/react-query';
import { GraphQLClient } from 'graphql-request';
import { fetchGalleryAsync } from './gallery';

const { REACT_APP_CONTENTFUL_API_URL, REACT_APP_CONTENTFUL_SPACE_ID: SPACE_ID, REACT_APP_CONTENTFUL_ACCESS_TOKEN: ACCESS_TOKEN } = process.env;

const contentfulClient = new GraphQLClient(`${REACT_APP_CONTENTFUL_API_URL}/${SPACE_ID}`, {
  headers: {
    Authorization: `Bearer ${ACCESS_TOKEN}`,
  },
});

export const useGallery = () => {
  const response = useQuery(['gallery'], async () => fetchGalleryAsync({ client: contentfulClient }));
  return response;
};
