import { BrowserRouter, Route } from 'react-router-dom';
import './css/app.css';
import HeaderBar from './components/HeaderBar/HeaderBar';
import Home from './views/Home/Home';
import Works from './views/Works/Works';
import { FC } from 'react';

const App: FC = () => {
  return (
    <BrowserRouter>
      <div>
        <HeaderBar />
        <div className="h-full w-full mt-[60px] md:mt-0px">
          <Route path="/" exact render={() => <Home />} />
          <Route path="/works" exact render={() => <Works />} />
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;
